var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex justify-space-between flex-wrap"},[(_vm.isAdminUser)?_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","color":"primary"},on:{"click":function($event){return _vm.$router.push('/app/template/create')}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add new template ")],1):_vm._e()],1)]),_c('v-card-title',[_vm._v(" Templates "),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1 table-one",attrs:{"search":_vm.search,"headers":_vm.headers,"footer-props":{ 'items-per-page-options': [-1, 15, 30, 50, 100] },"items":_vm.getTemplates.length ? _vm.getTemplates : [],"item-key":"id","multi-sort":""},scopedSlots:_vm._u([{key:"item.is_page",fn:function(ref){
var item = ref.item;
return [(item.is_page)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","icon":""},on:{"click":function($event){return _vm.$router.push(("/app/template/" + (item.id) + "/edit"))}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),(_vm.isAdminUser)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"danger","dark":"","icon":""},on:{"click":function($event){return _vm.showRemoveDialog(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])]):_vm._e()],1)]}}])})],1),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.selectedId)?_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" Delete template ")]),_c('v-card-text',[_vm._v(" The template will be deleted permanently. Are you sure? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"danger","text":"","loading":_vm.getTemplateLoading},on:{"click":_vm.deleteTemplate}},[_vm._v(" Delete ")])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }