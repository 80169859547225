<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap">
            <v-btn
              v-if="isAdminUser"
              class="ma-2"
              dark
              color="primary"
              @click="$router.push('/app/template/create')"
            >
              <v-icon>mdi-plus</v-icon>
              Add new template
            </v-btn>
          </div>
        </v-card-title>
        <v-card-title>
          Templates
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :search="search"
          :headers="headers"
          :footer-props="{ 'items-per-page-options': [-1, 15, 30, 50, 100] }"
          :items="getTemplates.length ? getTemplates : []"
          item-key="id"
          class="elevation-1 table-one"
          multi-sort
        >
          <template v-slot:item.is_page="{ item }">
            <v-icon v-if="item.is_page">mdi-check</v-icon>
          </template>
          <template v-slot:item.action="{ item }">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="success"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="$router.push(`/app/template/${item.id}/edit`)"
                  >
                    <v-icon>mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top v-if="isAdminUser">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="danger"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="showRemoveDialog(item.id)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </base-card>
      <v-dialog v-model="dialog" max-width="290">
        <v-card v-if="selectedId">
          <v-card-title class="text-h6">
            Delete template
          </v-card-title>
          <v-card-text>
            The template will be deleted permanently. Are you sure?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn
              color="danger"
              text
              :loading="getTemplateLoading"
              @click="deleteTemplate"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";

export default {
  metaInfo: {
    title: "Template"
  },
  data() {
    return {
      model: "template",
      page: null,
      search: "",
      selectedId: null,
      shouldRemoveModel: false,
      dialog: false,
      headers: [
        { text: "Name", value: "name" },
        { text: "View", value: "view" },
        { text: "Page", value: "is_page" },
        { text: "Action", value: "action", sortable: false }
      ]
    };
  },
  mounted() {
    this.page = this.$store.getters.getPage({ model: this.model });
  },
  methods: {
    ...mapActions(["removeTemplate"]),
    getPermissions(page) {
      return _.join(_.get(page, "actions"));
    },
    showRemoveDialog(id) {
      this.selectedId = id;
      this.dialog = true;
    },
    async deleteTemplate() {
      await this.removeTemplate(this.selectedId);
      this.dialog = false;
    }
  },
  computed: {
    ...mapGetters(["getTemplates", "getTemplateLoading", "isAdminUser"])
  }
};
</script>
<style lang="scss" scoped>
::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }
      th {
        span {
          font-size: 16px;
          color: #304156;
        }
      }
    }
    tr {
      td {
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }
    }
  }
}
</style>
